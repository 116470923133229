import type { AxiosResponse } from 'axios';
import { http } from './Connect';
import { TSubscribeStatus } from '@/api/schema';

/**
 * Get subscribe user.
 * @return Promise with subscribe data.
 */
export async function getSubscribeStatus(
  subscriptionToken: string
): Promise<TSubscribeStatus> {
  return http
    .get('/api/v1/subscription/status', {
      params: {
        subscriptionToken
      }
    })
    .then(({ data }: AxiosResponse): TSubscribeStatus => data);
}

/**
 * Set subscribe user.
 * @return Promise with set subscribe user data.
 */
export async function setSubscribeStatus(
  subscriptionToken: string
): Promise<TSubscribeStatus> {
  return http
    .post('/api/v1/subscription/subscribeToggle', null, {
      params: {
        subscriptionToken
      },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): TSubscribeStatus => data);
}
