















import Logo from '@/assets/images/Logo.svg?inline';
import { getSubscribeStatus, setSubscribeStatus } from '@/api/Subscribe';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { AxiosError } from 'axios';

export default {
  name: 'UnsubscribeView',

  components: {
    Logo
  },

  data(): any {
    return {
      loading: false,
      subscribe: true,
      token: null
    };
  },

  computed: {
    title(): string {
      if (this.subscribe) {
        return 'Do you want to unsubscribe from TruePlay Integration Status?';
      }

      return 'Subscribe to TruePlay Integration Status';
    },

    description(): string {
      if (this.subscribe) {
        return `You'll stop receiving messages about TruePlay Integration Status.`;
      }

      return 'Subscribe to receive our TruePlay Integration Status updates';
    },

    buttonText(): string {
      return this.subscribe ? 'Unsubscribe' : 'Subscribe';
    }
  },

  methods: {
    getSubscribeStatus(): void {
      getSubscribeStatus(this.token)
        .then((response) => {
          this.subscribe = response.subscriptionStatus;
        })
        .catch((error: AxiosError) => {
          errorToastMessage(error);

          this.$router.push('/login');
        });
    },

    setSubscribeStatus(): void {
      this.loading = true;

      setSubscribeStatus(this.token)
        .then((response) => {
          const isSubscribe = response.subscriptionStatus;

          this.$toast.success(
            `You have successfully ${
              isSubscribe ? 'subscribed' : 'unsubscribed'
            }`
          );

          this.subscribe = isSubscribe;
        })
        .catch(errorToastMessage)
        .finally(() => {
          this.loading = false;
        });
    },

    getSubscribeToken(): void {
      const token = this.$route.query.subscribeToken;

      if (token) {
        this.token = token;
        this.$router.replace({ query: '' });
      } else {
        this.$router.push('/login');
      }
    }
  },

  created(): void {
    this.getSubscribeToken();

    if (this.token) this.getSubscribeStatus();
  }
};
